import React from "react";

import styles from "./ItemDetailRow.module.scss";

interface IProps {
  label?: string;
  data?: any;
}

const ItemDetailRow = ({ data, label }: IProps) => (
  <tr className={styles.row}>
    <td className={styles.label}>{label}</td>
    <td className={styles.data}>{data}</td>
  </tr>
);

export default ItemDetailRow;
