import gql from "graphql-tag";

import { ItemTypeCategoryFragment } from "./ItemTypeCategoryFragment";

export const ItemTypeFragment = gql`
  fragment ItemType on ItemTypeObjectType {
    id
    name
    itemTypeCategory {
      ...ItemTypeCategory
    }
  }
  ${ItemTypeCategoryFragment}
`;
