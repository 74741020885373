import React, { Component } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { ButtonBase } from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";
import * as R from "ramda";

import Page from "../../components/Page";
import FetchingLoader from "../../components/FetchingLoader/FetchingLoader";
import SearchBar from "../SearchBar";
import Filters from "../Filters";
import ItemList from "../../components/ItemList";
import { IAppState } from "../../reducers";
import { FetchingStateEnumType } from "../../constants/FetchingState";
import IItem from "../../common/interfaces/IItem";
import { getFilteredItemsAction } from "../../actions/Filter";
import { downloadInventoryFile } from "../../actions/Items";
import { IFilterState } from "../../reducers/Filter";

import styles from "./ItemsPage.module.scss";

interface IProps {
  items: IItem[];
  itemsFetchingState: FetchingStateEnumType;
  filter: IFilterState;
  getFilteredItemsAction: any;
  history: any;
}

class ItemsPage extends Component<IProps> {
  componentDidMount() {
    if (this.props.itemsFetchingState !== FetchingStateEnumType.FETCHED) {
      this.props.getFilteredItemsAction({ debounce: false });
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.filter.categories && prevProps.filter !== this.props.filter) {
      this.props.getFilteredItemsAction();
    }
  }

  render() {
    const { items, itemsFetchingState } = this.props;

    return (
      <Page
        sidePanelContent={<Filters />}
        appBarContent={<SearchBar />}
        actionButtons={
          <ButtonBase
            className={styles.excelButton}
            onClick={downloadInventoryFile}
          >
            <CloudDownload className={styles.icon} />
            Excel
          </ButtonBase>
        }
      >
        <FetchingLoader fetchingState={itemsFetchingState}>
          <div className={styles.items}>
            <ItemList items={items} redirectTo={this.props.history.push} />
          </div>
        </FetchingLoader>
      </Page>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  items: state.items.items,
  itemsFetchingState: state.items.fetchingState,
  filter: state.filter
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getFilteredItemsAction: bindActionCreators(getFilteredItemsAction, dispatch)

});

const connector = R.compose<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default connector(ItemsPage);
