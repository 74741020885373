import React from "react";
import * as R from "ramda";

import CategoryFilterItem from "../CategoryFilterItem/index";
import { ICategorySelect } from "../../reducers/Filter";

interface IProps {
  categories: any[];
  categoriesSelect: ICategorySelect[];
  toggleCategory: any;
  toggleCategoryItem: any;
}

const CategoryFilterList = ({
  categories,
  categoriesSelect,
  toggleCategory,
  toggleCategoryItem
}: IProps) => (
  <div>
    {R.map((category: any) => (
      <CategoryFilterItem
        key={category.id}
        category={category}
        categorySelect={
          R.find(R.propEq("id", category.id))(categoriesSelect) || []
        }
        toggleCategory={toggleCategory}
        toggleCategoryItem={toggleCategoryItem}
      />
    ))(categories)}
  </div>
);

export default CategoryFilterList;
