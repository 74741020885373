import gql from "graphql-tag";

export const UserFragment = gql`
  fragment User on UserObjectType {
    id
    username
    firstname
    lastname
    role
  }
`;
