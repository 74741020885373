import {
  FILTER_RESET,
  FILTER_SET_STATE_TYPE,
  FILTER_SET_BRANCH_ID,
  FILTER_SET_CONTAINS,
  FILTER_INIT_CATEGORIES,
  FILTER_TOGGLE_CATEGORY,
  FILTER_TOGGLE_CATEGORY_ITEM,
  USER_LOGOUT
} from "../constants/ActionTypes";
import { StateTypeEnumType } from "../globalGenereatedTypes";

export interface ICategoryItemSelect {
  id: string;
  isSelected: boolean;
}

export interface ICategorySelect {
  id: string;
  isSelected: boolean;
  isIndeterminate: boolean;
  itemTypes: ICategoryItemSelect[];
}

export interface IFilterState {
  stateType?: StateTypeEnumType;
  branchId?: number;
  categories?: ICategorySelect[];
  contains?: string;
}

export const defaultFilterState: IFilterState = {};

export function filterReducer(state: IFilterState, action: any): IFilterState {
  switch (action.type) {
    case FILTER_RESET: {
      return {
        categories: action.categories
      };
    }
    case FILTER_SET_STATE_TYPE: {
      return {
        ...state,
        stateType: action.stateType
      };
    }
    case FILTER_SET_BRANCH_ID: {
      return {
        ...state,
        branchId: parseInt(action.branchId, 10)
      };
    }
    case FILTER_SET_CONTAINS: {
      return {
        ...state,
        contains: action.contains
      };
    }
    case FILTER_INIT_CATEGORIES:
    case FILTER_TOGGLE_CATEGORY:
    case FILTER_TOGGLE_CATEGORY_ITEM: {
      return {
        ...state,
        categories: action.categories
      };
    }
    case USER_LOGOUT: {
      return defaultFilterState;
    }
    default:
      return state;
  }
}
