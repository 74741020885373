import React from "react";

import Page from "../../components/Page";

const NotFoundPage = () => (
  <Page>
    <div>Page not found</div>
  </Page>
);

export default NotFoundPage;
