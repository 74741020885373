import * as R from "ramda";

import {
  ICategoryItemSelect,
  ICategorySelect,
  IFilterState
} from "../../reducers/Filter";
import { MoveTypeEnumType } from "../../globalGenereatedTypes";
import { IFilter } from "../interfaces/IFIlter";

export default function filterStateToFilterItemsInput(
  filterState: IFilterState
) {
  const { stateType, branchId, categories, contains } = filterState;

  const filter: IFilter = {
    stateType,
    contains
  };

  if (branchId) {
    filter.branchInfo = {
      branchId,
      moveType: MoveTypeEnumType.TO_WAREHOUSE
    };
  }

  if (categories) {
    const itemCategories: number[] = [];
    const itemTypeIds: number[] = [];
    R.map(
      R.compose(
        R.map(
          R.ifElse(
            R.propEq("isSelected", true),
            (itemSelection: ICategoryItemSelect) =>
              itemTypeIds.push(parseInt(itemSelection.id, 10)),
            // tslint:disable-next-line
            () => {}
          )
        ),
        R.ifElse(
          R.propEq("isSelected", true),
          (categorySelection: ICategorySelect): ICategoryItemSelect[] => {
            itemCategories.push(parseInt(categorySelection.id, 10));
            return categorySelection.itemTypes || [];
          },
          (categorySelection: ICategorySelect) =>
            categorySelection.itemTypes || []
        )
      )
    )(categories);

    filter.itemTypeIds = itemTypeIds;
    filter.itemTypeCategoryIds = itemCategories;
  }
  return filter;
}
