import { Item_dimensions } from "../api/fragments/__generatedTypes__/Item";

export default (dimension: Item_dimensions | null | undefined) => {
  if (!dimension) {
    return "-";
  }
  const dimensions = [];
  if (dimension.length) {
    dimensions.push(`d.${dimension.length}mm`);
  }
  if (dimension.depth) {
    dimensions.push(`hl.${dimension.depth}mm`);
  }
  if (dimension.height) {
    dimensions.push(`d.${dimension.height}mm`);
  }

  return dimensions.join(",");
};
