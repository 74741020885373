import { StateTypeEnumType } from "../../globalGenereatedTypes";

export default (stateType: StateTypeEnumType) => {
  switch (stateType) {
    case StateTypeEnumType.NEW:
      return "Nový";
    case StateTypeEnumType.USED:
      return "Použitý";
    case StateTypeEnumType.DESTROYED:
      return "Poškozený";
    default:
      return "";
  }
};
