import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import * as R from "ramda";
import { InputBase } from "@material-ui/core";
import { Search } from "@material-ui/icons";

import { setContains as setContainAction } from "../../actions/Filter";

import styles from "./SearchBar.module.scss";

// TODO: extract TextInput - reuses in filter (store number)
// this container handle searching, design will be in TextInput -> reuse

interface IProps {
  setContains: any;
}

const SearchBar = ({ setContains }: IProps) => {
  function handleInputChange(event: any) {
    setContains(event.target.value);
  }

  return (
    <div className={styles.searchBar}>
      <Search className={styles.searchIcon} />
      <InputBase
        className={styles.searchInput}
        placeholder="Vyhledej podle pobočky, názvu nebo ID"
        onChange={handleInputChange}
      />
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setContains: bindActionCreators(setContainAction, dispatch)
});

const connector = R.compose<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default connector(SearchBar);
