import React from "react";
import * as R from "ramda";
import moment from "moment";

import ItemDetailRow from "../../components/ItemDetailRow";
import IItem from "../../common/interfaces/IItem";
import dimensionToDescription from "../../common/utils/dimensionToDescription";
import mapStateTypeToString from "../../common/utils/mapStateTypeToString";

import styles from "./ItemDetails.module.scss";

interface IProps {
  item: IItem;
}

const ItemDetails = ({ item }: IProps) => (
  <div className={styles.wrapper}>
    <h1>{item.itemType.name}</h1>
    <table className={styles.detailTable}>
      <tbody>
        <ItemDetailRow
          label="kategorie"
          data={R.path(["itemType", "itemTypeCategory", "name"])(item)}
        />
        <ItemDetailRow label="název" data={item.itemType.name} />
        <ItemDetailRow
          label="popis"
          data={dimensionToDescription(item.dimensions)}
        />
        <ItemDetailRow label="id" data={item.id.toString()} />
        <ItemDetailRow
          label="stav"
          data={mapStateTypeToString(item.stateType)}
        />
        <ItemDetailRow
          label="počet kusů"
          data={item.itemCount && item.itemCount.toString()}
        />
        <ItemDetailRow
          label="prodejna"
          data={item.includedInCards[0].branchId}
        />
        <ItemDetailRow label="poznámky" data={item.notes || "-"} />
        <ItemDetailRow
          label="datum zadání"
          data={moment(item.dateCreated).format("hh:mm, DD.MM.YYYY")}
        />
        <ItemDetailRow
          label="zadal"
          data={`${item.createdBy.firstname} ${item.createdBy.lastname}`}
        />
        <ItemDetailRow />
        <ItemDetailRow />
        <ItemDetailRow
          label="umístění"
          data={
            item.warehouse && item.warehouse[0] && item.warehouse[0].location
          }
        />
        <ItemDetailRow
          label="počet v umístění"
          data={
            item.warehouse && item.warehouse[0] && item.warehouse[0].itemCount
          }
        />
        <ItemDetailRow
          label="počet palet"
          data={
            item.warehouse && item.warehouse[0] && item.warehouse[0].palletCount
          }
        />
      </tbody>
    </table>
  </div>
);

export default ItemDetails;
