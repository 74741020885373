import gql from "graphql-tag";

import { ItemFragment } from "../../fragments/ItemFragment";
import client from "../../client";

import {
  getFilterItemsQuery,
  getFilterItemsQuery_items
} from "./__generatedTypes__/getFilterItemsQuery";

export const FILTER_ITEMS_QUERY = gql`
  query getFilterItemsQuery($filters: filterItemsInput) {
    items(filters: $filters) {
      ...Item
    }
  }
  ${ItemFragment}
`;

export default async function getFilterItems(
  filters: any = {}
): Promise<getFilterItemsQuery_items[]> {
  const result = await client.query<getFilterItemsQuery>({
    query: FILTER_ITEMS_QUERY,
    variables: { filters },
    fetchPolicy: "no-cache"
  });
  if (result.errors) {
    throw new Error(result.errors[0].message);
  }
  if (result.data && result.data.items) {
    return result.data.items;
  } else {
    throw new Error(
      `Items not found in query data response: ${JSON.stringify(result.data)}`
    );
  }
}
