import { Dispatch } from "redux";

import {
  GET_ITEMS_REQUEST,
  GET_ITEMS_SUCCESS,
  GET_ITEMS_ERROR
} from "../constants/ActionTypes";
import { Item } from "../common/api/fragments/__generatedTypes__/Item";
import getFilterItems from "../common/api/queries/filterItemsQuery/filterItemsQuery";
import generateInventoryFile from "../common/api/mutations/warehouseMutation/warehouseMutation"

/*
  GET ITEMS
 */

function dispatchItemsRequest(): any {
  return {
    type: GET_ITEMS_REQUEST
  };
}
function dispatchItemsSuccess(items: Item[]): any {
  return {
    type: GET_ITEMS_SUCCESS,
    items
  };
}

function dispatchItemsError(message: string): any {
  return {
    type: GET_ITEMS_ERROR,
    errorMessage: message
  };
}

export function getItemsAction(filters: any = {}) {
  return (dispatch: Dispatch) => {
    dispatch(dispatchItemsRequest());
    return getFilterItems(filters)
      .then(res => {
        dispatch(dispatchItemsSuccess(res));
      })
      .catch(res => {
        dispatch(dispatchItemsError("authentication failed"));
        throw new Error(res);
      });
  };
}


export function downloadInventoryFile() {
  generateInventoryFile().then(res => {
    window.open(`${process.env.REACT_APP_ENDPOINT}/files/warehouse-inventory/`+res.data.createCurrentWarehouseInventoryFile.fileId)
  })
}
