import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as R from "ramda";
import { ButtonBase } from "@material-ui/core";

import FetchingLoader from "../../components/FetchingLoader";
import FilterCategory from "../../components/FilterCategory";
import CategoryFilterList from "../../components/CategoryFilterList";
import TextFilter from "../../components/TextFilter";
import RadioFilter from "../../components/RadioFilter";
import {
  getAllCategoriesAction,
  getAllCategoriesActionType
} from "../../actions/Categories";
import {
  resetFilter,
  initCategories,
  setBranchId,
  setStateType,
  toggleCategory,
  toggleCategoryItem
} from "../../actions/Filter";
import { IAppState } from "../../reducers";
import { IFilterState } from "../../reducers/Filter";
import { ICategoriesState } from "../../reducers/Categories";
import { FetchingStateEnumType } from "../../constants/FetchingState";
import isDefaultFilterState from "../../common/utils/isDefaultFilterState";

import styles from "./Filters.module.scss";

interface IProps {
  getAllCategoriesAction: getAllCategoriesActionType;
  setBranchId: any;
  resetFilter: any;
  initCategories: any;
  setStateType: any;
  toggleCategory: any;
  toggleCategoryItem: any;
  filter: IFilterState;
  categories: ICategoriesState;
  categoriesFetchingState: FetchingStateEnumType;
}

// tslint:disable-next-line
interface IState {}

class Filters extends Component<IProps, IState> {
  componentDidMount() {
    if (this.props.categoriesFetchingState !== FetchingStateEnumType.FETCHED) {
      this.props.getAllCategoriesAction();
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      prevProps.categories.fetchingState === FetchingStateEnumType.FETCHING &&
      prevProps.categories.itemTypeCategories !==
        this.props.categories.itemTypeCategories
    ) {
      this.props.initCategories();
    }
  }

  render() {
    const isDefault = isDefaultFilterState(this.props.filter);

    return (
      <div className={styles.wrapper}>
        <FilterCategory header="Kategorie">
          <FetchingLoader fetchingState={this.props.categories.fetchingState}>
            <CategoryFilterList
              categories={
                R.path(["itemTypeCategories"], this.props.categories) || []
              }
              categoriesSelect={this.props.filter.categories || []}
              toggleCategory={this.props.toggleCategory}
              toggleCategoryItem={this.props.toggleCategoryItem}
            />
          </FetchingLoader>
        </FilterCategory>
        <FilterCategory header="Stav">
          <RadioFilter
            filterId="stateType"
            values={[
              { value: "NEW", label: "Nový" },
              { value: "USED", label: "Použitý" },
              { value: "DESTROYED", label: "Poškozený" }
            ]}
            defaultValue={this.props.filter.stateType}
            handleChange={this.props.setStateType}
          />
        </FilterCategory>
        <FilterCategory header="Prodejna">
          <TextFilter
            filterId="branchId"
            placeholder="Číslo prodejny"
            handleChange={this.props.setBranchId}
            defaultValue={this.props.filter.branchId}
            type="number"
          />
        </FilterCategory>
        <ButtonBase
          className={`${styles.resetButton} ${isDefault ? styles.hidden : ""}`}
          onClick={this.props.resetFilter}
          disabled={isDefault}
        >
          smazat filter
        </ButtonBase>
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  filter: state.filter,
  categories: state.categories,
  categoriesFetchingState: state.categories.fetchingState
});

const mapDispatchToProps = (dispatch: any) => ({
  getAllCategoriesAction: bindActionCreators(getAllCategoriesAction, dispatch),
  setBranchId: bindActionCreators(setBranchId, dispatch),
  setStateType: bindActionCreators(setStateType, dispatch),
  resetFilter: bindActionCreators(resetFilter, dispatch),
  initCategories: bindActionCreators(initCategories, dispatch),
  toggleCategory: bindActionCreators(toggleCategory, dispatch),
  toggleCategoryItem: bindActionCreators(toggleCategoryItem, dispatch)
});

const connector = R.compose<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default connector(Filters);
