import React, { ReactNode } from "react";

import styles from "./FilterCategory.module.scss";

interface IProps {
  header: string;
  children: ReactNode;
}

const FilterCategory = ({ header, children }: IProps) => (
  <div className={styles.wrapper}>
    <div className={styles.header}>{header}</div>
    <div className={styles.content}>{children}</div>
  </div>
);

export default FilterCategory;
