import React from "react";
import { Provider } from "react-redux";
import { ApolloProvider } from "react-apollo";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import createStore from "../../store";
import apolloClient from "../../common/api/client";
import LoginPage from "../LoginPage";
import ItemsPage from "../ItemsPage";
import DetailPage from "../DetailPage";
import NotFoundPage from "../NotFoundPage";
import PrivateRoute from "../../components/PrivateRoute";

import styles from "./App.module.scss";

const store = createStore();

console.log(process.env.REACT_APP_PATH)
const App = () => (
  <ApolloProvider client={apolloClient}>
    <Provider store={store}>
      <Router basename={process.env.REACT_APP_PATH}>
        <div className={styles.App}>
          <Switch>
            <Route exact path={"/login"} component={LoginPage} />
            <PrivateRoute exact path="/" component={ItemsPage} />
            <PrivateRoute exact path={"/detail/:id"} component={DetailPage} />
            <Route path={"/"} component={NotFoundPage} />
          </Switch>
        </div>
      </Router>
    </Provider>
  </ApolloProvider>
);

export default App;
