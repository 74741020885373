import React from "react";
import { Checkbox } from "@material-ui/core";

import styles from "./CategoryFilterItemLine.module.scss";

interface IProps {
  label: string;
  isChecked: boolean;
  handleSelect: any;
  header?: boolean;
  isIndeterminate?: boolean;
  onCollapseClick?: any;
  pointerCursor?: boolean;
}

const CategoryFilterItemLine = ({
  label,
  isChecked,
  handleSelect,
  header,
  isIndeterminate,
  onCollapseClick,
  pointerCursor
}: IProps) => (
  <div
    className={`${styles.wrapper} ${isChecked && styles.active} ${!header &&
      styles.pointerCursor}`}
    onClick={!header ? handleSelect : undefined}
  >
    <div
      className={`${styles.label} ${header &&
        pointerCursor &&
        styles.pointerCursor}`}
      onClick={header ? onCollapseClick : undefined}
    >
      {label}
    </div>
    <Checkbox
      checked={isChecked}
      indeterminate={isIndeterminate}
      className={`${styles.checkbox} ${header ? styles.header : ""} ${
        isChecked ? styles.checked : ""
      }`}
      color="default"
      onClick={header ? handleSelect : undefined}
    />
  </div>
);

export default CategoryFilterItemLine;
