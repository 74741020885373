import React, { ReactNode } from "react";

import styles from "./SidePanel.module.scss";

interface IProps {
  children: ReactNode;
}

const SidePanel = ({ children }: IProps) => (
  <div className={styles.sidePanel}>{children}</div>
);

export default SidePanel;
