import gql from 'graphql-tag';

import { ItemTypeWithoutCategoryFragment } from './ItemTypeWithoutCategoryFragment';

export const ItemTypeCategoryWithTypesFragment = gql`
  fragment ItemTypeCategoryWithTypes on ItemTypeCategoryObjectType {
    id
    name
    itemTypes {
      ...ItemTypeWithoutCategory
    }
  }
  ${ItemTypeWithoutCategoryFragment}
`;
