/* tslint:disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Cards goes from branch TO_WAREHOUSE or FROM_WAREHOUSE to branch
 */
export enum MoveTypeEnumType {
  FROM_WAREHOUSE = "FROM_WAREHOUSE",
  TO_WAREHOUSE = "TO_WAREHOUSE"
}

export enum RoleTypeEnumType {
  ADMIN = "ADMIN",
  FITTER = "FITTER",
  MANAGER = "MANAGER",
  WAREHOUSEMAN = "WAREHOUSEMAN"
}

export enum StateTypeEnumType {
  DESTROYED = "DESTROYED",
  NEW = "NEW",
  USED = "USED"
}

export interface CreateCardInput {
  moveType: MoveTypeEnumType;
  branchId?: number | null;
}

export interface CreateDimensionsInput {
  depth?: number | null;
  height?: number | null;
  itemTypeId: number;
  length?: number | null;
}

export interface CreateItemInput {
  cardId: number;
  dimensionsId?: number | null;
  itemCount: number;
  itemTypeId: number;
  mainPhotoId?: number | null;
  notes?: string | null;
  photoIds?: number[] | null;
  stateType: StateTypeEnumType;
}

export interface CreateItemTypeInput {
  itemTypeCategoryId: number;
  name: string;
}

export interface GetCardInput {
  id: number;
}

export interface LoginInput {
  username: string;
  password: string;
}

export interface createPhotoInput {
  data: string;
  encoding: string;
  filename: string;
  mimetype: string;
}

export interface createWarehouseLocationInput {
  location: string;
}

export interface moveItemInput {
  itemId: number;
  itemCount: number;
  moveType: MoveTypeEnumType;
  palletCount: number;
  warehouseLocationId: number;
}

export interface updateItemInput {
  id: number;
  dimensionsId?: number | null;
  itemCount?: number | null;
  itemTypeId?: number | null;
  mainPhotoId?: number | null;
  notes?: string | null;
  photoIds?: number[] | null;
  stateType?: StateTypeEnumType | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
