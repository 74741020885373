import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";

import IItem from "../../common/interfaces/IItem";

import ItemRow from "./ItemRow";
import styles from "./ItemList.module.scss";

interface IProps {
  items: IItem[];
  redirectTo: any;
}

const ItemList = ({ items, redirectTo }: IProps) => (
  <div className={styles.container}>
    <Table>
      <TableHead className={styles.header}>
        <TableRow>
          <TableCell>Foto</TableCell>
          <TableCell>Kategorie</TableCell>
          <TableCell>Název</TableCell>
          <TableCell>Popis</TableCell>
          <TableCell>Prodejna</TableCell>
          <TableCell>ks</TableCell>
          <TableCell>stav</TableCell>
          <TableCell>naskladnění</TableCell>
          <TableCell>umístění</TableCell>
          <TableCell>ID</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item, index) => (
          <ItemRow key={index} item={item} redirectTo={redirectTo} />
        ))}
      </TableBody>
    </Table>
  </div>
);

export default ItemList;
