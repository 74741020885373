import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as R from "ramda";
import { ButtonBase } from "@material-ui/core";

import { userLogoutAction, userLogoutActionType } from "../../actions/Auth";
import { IAppState } from "../../reducers";
import Logo from "../../components/Logo/Logo";

import styles from "./AppBar.module.scss";

interface IProps {
  content?: ReactNode;
  actionButtons?: ReactNode | ReactNode[];
  isLogged: boolean;
  logoutAction: userLogoutActionType;
}

const AppBar = ({ content, actionButtons, isLogged, logoutAction }: IProps) => (
  <div className={styles.appBar}>
    <div className={styles.logoWrapper}>
      <Logo className={styles.logo} />
    </div>
    <div className={styles.content}>{content}</div>
    {!!actionButtons && (
      <div className={styles.actionButtons}>{actionButtons}</div>
    )}
    {isLogged && (
      <ButtonBase className={styles.logoutButton} onClick={logoutAction}>
        Odhlásit
      </ButtonBase>
    )}
  </div>
);

const mapStateToProps = (state: IAppState) => ({
  isLogged: state.auth.isLogged
});

const mapDispatchToProps = (dispatch: any) => ({
  logoutAction: bindActionCreators(userLogoutAction, dispatch)
});

const connector = R.compose<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default connector(AppBar);
