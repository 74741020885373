import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as R from "ramda";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "@material-ui/lab";

import { IAppState } from "../../reducers";
import {
  getItemDetailAction,
  getItemDetailActionType
} from "../../actions/Detail";
import getItemByIdFromAppState from "../../common/utils/getItemByIdFromAppState";
import Page from "../../components/Page";
import DetailPageLayout from "../../components/DetailPageLayout";
import IItem from "../../common/interfaces/IItem";
import FetchingLoader from "../../components/FetchingLoader/FetchingLoader";
import { FetchingStateEnumType } from "../../constants/FetchingState";

import styles from "./DetailPage.module.scss";

interface IProps {
  item: IItem;
  detailFetchingState: FetchingStateEnumType;
  getItemDetailAction: getItemDetailActionType;
  match: any;
}

class DetailPage extends Component<IProps> {
  componentDidMount() {
    this.props.getItemDetailAction();
  }

  render() {
    const breadcrumbs = (
      <Breadcrumbs arial-label="breadcrumbs" separator=">">
        <Link
          color="inherit"
          to="/"
          className={`${styles.breadcrumbItem} ${styles.listLink}`}
        >
          List
        </Link>
        <div className={styles.breadcrumbItem}>
          {this.props.item && this.props.item.itemType.name}
        </div>
      </Breadcrumbs>
    );

    return (
      <Page appBarContent={breadcrumbs}>
        <FetchingLoader fetchingState={this.props.detailFetchingState}>
          <DetailPageLayout
            photos={(this.props.item && this.props.item.photos) || []}
            item={this.props.item}
          />
        </FetchingLoader>
      </Page>
    );
  }
}

const mapStateToProps = (state: IAppState, ownProps: IProps) => ({
  item: getItemByIdFromAppState(state, ownProps.match.params.id),
  detailFetchingState: state.items.fetchingState
});

const mapDispatchToProps = (dispatch: any) => ({
  getItemDetailAction: bindActionCreators(getItemDetailAction, dispatch)
});

const connector = R.compose<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default connector(DetailPage);
