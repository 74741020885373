import { Dispatch } from "redux";

import { FetchingStateEnumType } from "../constants/FetchingState";
import { IAppState } from "../reducers";

import { getFilteredItemsAction } from "./Filter";

export function getItemDetailAction() {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    if (getState().items.fetchingState !== FetchingStateEnumType.FETCHED) {
      dispatch<any>(getFilteredItemsAction({ debounce: false }));
    }
  };
}

export type getItemDetailActionType = () => () => void;
