import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router";
import * as R from "ramda";
import {
  Button,
  TextField,
  Card,
  CardContent,
  CircularProgress
} from "@material-ui/core";

import { userLoginAction, userLoginActionType } from "../../actions/Auth";
import Page from "../../components/Page";
import Logo from "../../components/Logo";
import { IAppState } from "../../reducers";
import { User } from "../../common/api/fragments/__generatedTypes__/User";

import styles from "./LoginPage.module.scss";

interface IProps {
  isLogged: boolean;
  user: User;
  loginAction: userLoginActionType;
  location: any;
}

type loginState = any;

class LoginPage extends Component<IProps, loginState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loading: false
    };
  }

  handleUsernameChange = (event: any) => {
    this.setState({ username: event.target.value });
  };

  handlePasswordChange = (event: any) => {
    this.setState({ password: event.target.value });
  };

  handleLogin = async () => {
    try {
      this.setState({ loading: true });
      // tslint:disable-next-line
      await this.props.loginAction({
        username: this.state.username,
        password: this.state.password
      });
      this.setState({ loading: false });
      this.setState({ redirectToReferrer: true });
    } catch (e) {
      this.setState({ loading: false });
      alert(e.message);
    }
  };

  render() {
    const { loading } = this.state;
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }
    return (
      <Page hideAppBar>
        <div className={styles.container}>
          <Logo width={"300px"} className={styles.logo} />
          <Card className={styles.card}>
            <CardContent>
              <form noValidate autoComplete="off">
                <TextField
                  className={styles.textField}
                  autoComplete="username"
                  placeholder="Přihlašovací jméno"
                  margin="normal"
                  variant="outlined"
                  onChange={this.handleUsernameChange}
                />
                <TextField
                  className={styles.textField}
                  type="password"
                  autoComplete="current-password"
                  placeholder="Heslo"
                  margin="normal"
                  variant="outlined"
                  onChange={this.handlePasswordChange}
                />
              </form>
              <Button
                disabled={loading}
                className={styles.button}
                onClick={this.handleLogin}
              >
                {loading ? (
                  <CircularProgress
                    size={26}
                    classes={{ svg: styles.spinner }}
                  />
                ) : (
                  "Přihlásit"
                )}
              </Button>
            </CardContent>
          </Card>
        </div>
      </Page>
    );
  }
}
const mapStateToProps = (state: IAppState) => ({
  isLogged: state.auth.isLogged,
  user: state.auth.user
});

const mapDispatchToProps = (dispatch: any) => ({
  loginAction: bindActionCreators(userLoginAction, dispatch)
});

const connector = R.compose<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default connector(LoginPage);
