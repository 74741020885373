import React from "react";

interface IProps {
  height?: string;
  width?: string;
  alt?: string;
  className?: string;
}

const Logo = ({ height, width, alt, className }: IProps) => (
  <img
    alt={alt}
    height={height}
    width={width}
    className={className}
    src={process.env.PUBLIC_URL + "/logo.png"}
  />
);

export default Logo;
