import React from "react";

import { Item_photos } from "../../common/api/fragments/__generatedTypes__/Item";

import styles from "./PhotoGalleryPreviewItem.module.scss";

interface IProps {
  active?: boolean;
  photo: Item_photos;
  handleClick: () => void;
}

const PhotoGaleryPreviewItem = ({ active, photo, handleClick }: IProps) => (
  <div
    className={`${styles.photoListItem} ${active && styles.active}`}
    onClick={handleClick}
  >
    <img src={photo.storageUrl} />
  </div>
);

export default PhotoGaleryPreviewItem;
