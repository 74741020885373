import {
  USER_LOGIN_ERROR,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT
} from "../constants/ActionTypes";
import { FetchingStateEnumType } from "../constants/FetchingState";
import { User } from "../common/api/fragments/__generatedTypes__/User";

export interface IAuthState {
  fetchingState: string;
  isLogged: boolean;
  user?: User;
  token?: string;
  errorMessage?: string;
}

export const defaultAuthState: IAuthState = {
  fetchingState: FetchingStateEnumType.INIT,
  isLogged: false
};

export function authReducer(state: IAuthState, action: any): IAuthState {
  switch (action.type) {
    case USER_LOGIN_REQUEST: {
      return {
        fetchingState: FetchingStateEnumType.FETCHING,
        isLogged: false
      };
    }
    case USER_LOGIN_SUCCESS: {
      return {
        fetchingState: FetchingStateEnumType.FETCHED,
        isLogged: true,
        token: action.token,
        user: action.user
      };
    }
    case USER_LOGIN_ERROR: {
      return {
        fetchingState: FetchingStateEnumType.ERROR,
        isLogged: false,
        errorMessage: action.errorMessage
      };
    }
    case USER_LOGOUT: {
      return {
        fetchingState: FetchingStateEnumType.INIT,
        isLogged: false
      };
    }
    default:
      return state;
  }
}
