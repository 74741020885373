import gql from "graphql-tag";

import { DimensionsFragment } from "./DimensionsFragment";
import { ItemTypeFragment } from "./ItemTypeFragment";
import { ItemWarehouseInfoFragment } from "./ItemWarehouseInfoFragment";
import { PhotoFragment } from "./PhotoFragment";
import { UserFragment } from "./UserFragment";

export const ItemFragment = gql`
  fragment Item on ItemObjectType {
    id
    itemType {
      ...ItemType
    }
    createdBy {
      ...User  
    }
    includedInCards {
      branchId  
      moveType
    }
    dateCreated
    itemCount
    stateType
    dimensions {
      ...Dimensions
    }
    notes
    photos {
      ...Photo
    }
    warehouse {
      ...ItemWarehouseInfo
    }
  }
  ${ItemWarehouseInfoFragment}
  ${DimensionsFragment}
  ${PhotoFragment}
  ${ItemTypeFragment}
  ${UserFragment}
`;
