import {
  GET_ALL_CATEGORIES_REQUEST,
  GET_ALL_CATEGORIES_SUCCESS,
  GET_ALL_CATEGORIES_ERROR,
  USER_LOGOUT
} from "../constants/ActionTypes";
import { FetchingStateEnumType } from "../constants/FetchingState";
import { ItemTypeCategory } from "../common/api/fragments/__generatedTypes__/ItemTypeCategory";

export interface ICategoriesState {
  fetchingState: FetchingStateEnumType;
  itemTypeCategories: ItemTypeCategory[];
}

export const defaultCategoriesState: ICategoriesState = {
  fetchingState: FetchingStateEnumType.INIT,
  itemTypeCategories: []
};

export function categoriesReducer(
  state: ICategoriesState,
  action: any
): ICategoriesState {
  switch (action.type) {
    case GET_ALL_CATEGORIES_REQUEST: {
      return {
        fetchingState: FetchingStateEnumType.FETCHING,
        itemTypeCategories: []
      };
    }
    case GET_ALL_CATEGORIES_SUCCESS: {
      return {
        fetchingState: FetchingStateEnumType.FETCHED,
        itemTypeCategories: action.itemTypeCategories
      };
    }
    case GET_ALL_CATEGORIES_ERROR: {
      return {
        fetchingState: FetchingStateEnumType.ERROR,
        itemTypeCategories: []
      };
    }
    case USER_LOGOUT: {
      return {
        fetchingState: FetchingStateEnumType.INIT,
        itemTypeCategories: []
      };
    }
    default:
      return state;
  }
}
