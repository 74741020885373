import React from "react";
import { InputBase } from "@material-ui/core";

import styles from "./TextFilter.module.scss";

interface IProps {
  filterId: string;
  handleChange: (value: string) => void;
  placeholder?: string;
  type?: string;
  defaultValue?: number;
}

const TextFilter = ({
  filterId,
  placeholder,
  handleChange,
  type,
  defaultValue
}: IProps) => {
  function handleInputChange(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    handleChange(event.currentTarget.value);
  }

  return (
    <div className={styles.textFilter}>
      <InputBase
        id={filterId}
        className={styles.input}
        placeholder={placeholder}
        type={type}
        value={defaultValue || ""}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default TextFilter;
