import gql from "graphql-tag";

import client from "../../client";
import { ItemTypeCategoryWithTypesFragment } from "../../fragments/ItemTypeCategoryWithTypesFragment";

import {
  getAllCategoriesQuery,
  getAllCategoriesQuery_itemTypeCategories
} from "./__generatedTypes__/getAllCategoriesQuery";

export const ALL_CATEGORIES_QUERY = gql`
  query getAllCategoriesQuery {
    itemTypeCategories {
      ...ItemTypeCategoryWithTypes
    }
  }
  ${ItemTypeCategoryWithTypesFragment}
`;

export default async function getAllCategories(): Promise<
  getAllCategoriesQuery_itemTypeCategories[]
> {
  const result = await client.query<getAllCategoriesQuery>({
    query: ALL_CATEGORIES_QUERY,
    fetchPolicy: "no-cache"
  });
  if (result.errors) {
    throw new Error(result.errors[0].message);
  }
  if (result.data && result.data.itemTypeCategories) {
    return result.data.itemTypeCategories;
  } else {
    throw new Error(
      `Item type categories not found in query data response: ${JSON.stringify(
        result.data
      )}`
    );
  }
}
