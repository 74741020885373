import * as R from "ramda";
import { Action, Dispatch } from "redux";

import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_ERROR,
  USER_LOGOUT
} from "../constants/ActionTypes";
import { User } from "../common/api/fragments/__generatedTypes__/User";
import { setToken, clearToken } from "../common/api/client";
import loginMutation from "../common/api/mutations/loginMutation/loginMutation";

export interface IUserLoginActionOptions {
  username: string;
  password: string;
}

export interface ICustomAction extends Action {
  type: string;
}

// tslint:disable-next-line
export interface IUserLoginRequest extends ICustomAction {}

export interface IUserLoginSuccess extends ICustomAction {
  token: string;
  user: User;
}

export interface IUserLoginError extends ICustomAction {
  errorMessage: string;
}

function dispatchLoginRequest(): IUserLoginRequest {
  return {
    type: USER_LOGIN_REQUEST
  };
}

function dispatchLoginSuccess(token: string, user: User): IUserLoginSuccess {
  return {
    type: USER_LOGIN_SUCCESS,
    token,
    user
  };
}

function dispatchLoginError(message: string): IUserLoginError {
  return {
    type: USER_LOGIN_ERROR,
    errorMessage: message
  };
}

export function userLoginAction(options: IUserLoginActionOptions) {
  // TODO: call api
  return (dispatch: Dispatch) => {
    dispatch(dispatchLoginRequest());
    return loginMutation({
      input: {
        username: options.username,
        password: options.password
      }
    })
      .then(res => {
        dispatch(
          dispatchLoginSuccess(
            R.path<any>(["data", "login", "token"])(res),
            R.path<any>(["data", "login", "user"])(res)
          )
        );
        setToken(R.path<any>(["data", "login", "token"])(res));
      })
      .catch(res => {
        dispatch(dispatchLoginError("authentication failed"));
        throw new Error(res);
      });
  };
}

export type userLoginActionType = (
  options: IUserLoginActionOptions
) => () => void;

/*
  LOGOUT
 */

// tslint:disable-next-line
export interface IUserLogout extends ICustomAction {}

function dispatchLogout(): IUserLogout {
  return {
    type: USER_LOGOUT
  };
}

export function userLogoutAction() {
  return (dispatch: Dispatch) => {
    clearToken();
    dispatch(dispatchLogout());
  };
}

export type userLogoutActionType = () => () => void;
