import React, { ReactNode } from "react";
import { Collapse, CircularProgress } from "@material-ui/core";

import { FetchingStateEnumType } from "../../constants/FetchingState";

import styles from "./FetchingLoader.module.scss";

interface IProps {
  children?: ReactNode;
  fetchingState: FetchingStateEnumType;
}

const FetchingLoader = ({ children, fetchingState }: IProps) => (
  <div className={styles.fetchingContainer}>
    {fetchingState !== FetchingStateEnumType.FETCHED && (
      <CircularProgress size={26} className={styles.spinner} />
    )}
    <Collapse in={fetchingState === FetchingStateEnumType.FETCHED}>
      {fetchingState === FetchingStateEnumType.FETCHED && children}
    </Collapse>
  </div>
);

export default FetchingLoader;
