import * as R from "ramda";
import React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import Moment from "react-moment";

import IItem from "../../common/interfaces/IItem";
import dimensionToDescription from "../../common/utils/dimensionToDescription";
import mapStateTypeToString from "../../common/utils/mapStateTypeToString";

import styles from "./ItemRow.module.scss";

interface IProps {
  item: IItem;
  redirectTo: any;
}

const ItemRow = ({ item, redirectTo }: IProps) => {
  function redirectToDetail() {
    redirectTo(`/detail/${item.id}`);
  }

  return (
    <TableRow onClick={redirectToDetail} className={styles.linkRow}>
      <TableCell>
        <img
          className={styles.img}
          src={R.propOr(
            "",
            "storageUrl",
            R.find(R.propEq("main", true), item.photos || [])
          )}
        />
      </TableCell>
      <TableCell className={`${styles.bold} ${styles.greyText}`}>
        {R.path(["itemType", "itemTypeCategory", "name"], item)}
      </TableCell>
      <TableCell className={styles.bold}>
        {R.path(["itemType", "name"], item)}
      </TableCell>
      <TableCell className={`${styles.bold} ${styles.greyText}`}>
        {dimensionToDescription(item.dimensions)}
      </TableCell>
      <TableCell>{R.path(["includedInCards", 0, "branchId"], item)}</TableCell>
      <TableCell>{item.itemCount}</TableCell>
      <TableCell>{mapStateTypeToString(item.stateType)}</TableCell>
      <TableCell>
        <Moment subtract={{ days: 1 }} format="DD.MM.YYYY">
          {item.dateCreated}
        </Moment>
      </TableCell>
      <TableCell>{R.path(["warehouse", 0, "location"], item)}</TableCell>
      <TableCell>#{item.id}</TableCell>
    </TableRow>
  );
};

export default ItemRow;
