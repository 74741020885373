import React, { Component } from "react";
import * as R from "ramda";

import { Item_photos } from "../../common/api/fragments/__generatedTypes__/Item";
import PhotoGalleryPreviewItem from "../PhotoGalleryPreviewItem";

import styles from "./PhotoGallery.module.scss";

interface IProps {
  photos: Item_photos[];
}

interface IState {
  detailedIndex: number;
}

class PhotoGallery extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const mainIndex = R.findIndex(R.propEq("main", true), this.props.photos);
    this.state = {
      detailedIndex: mainIndex
    };

    this.handlePhotoClick = this.handlePhotoClick.bind(this);
  }

  handlePhotoClick(index: number) {
    return () => {
      this.setState({
        detailedIndex: index
      });
    };
  }

  renderPhotoPreviews() {
    const mapIndexed = R.addIndex<any, any>(R.map);
    return mapIndexed(
      (photo: Item_photos, i: number) => (
        <PhotoGalleryPreviewItem
          active={this.state.detailedIndex === i}
          photo={photo}
          key={photo.id}
          handleClick={this.handlePhotoClick(i)}
        />
      ),
      this.props.photos
    );
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.photosList}>{this.renderPhotoPreviews()}</div>
        <div className={styles.photoDetail}>
          <img src={this.props.photos[this.state.detailedIndex].storageUrl} />
        </div>
      </div>
    );
  }
}

export default PhotoGallery;
