import * as R from "ramda";
import { Dispatch } from "redux";
import { debounce } from "ts-debounce";

import {
  DEBOUNCING_FETCH_FILTERED_ITEMS,
  FILTER_RESET,
  FILTER_SET_STATE_TYPE,
  FILTER_SET_BRANCH_ID,
  FILTER_SET_CONTAINS,
  FILTER_INIT_CATEGORIES,
  FILTER_TOGGLE_CATEGORY,
  FILTER_TOGGLE_CATEGORY_ITEM
} from "../constants/ActionTypes";
import { StateTypeEnumType } from "../globalGenereatedTypes";
import { IFilter } from "../common/interfaces/IFIlter";
import { IAppState } from "../reducers";
import filterStateToFilterItemsInput from "../common/utils/filterStateToFilterItemsInput";
import { ICategoryItemSelect, ICategorySelect } from "../reducers/Filter";

import { getItemsAction } from "./Items";

export function resetFilter() {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    dispatch({
      type: FILTER_RESET,
      categories: getInitialCategoriesState(
        R.pathOr([], ["categories", "itemTypeCategories"], getState())
      )
    });
  };
}

export function setStateType(stateType: StateTypeEnumType) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: FILTER_SET_STATE_TYPE,
      stateType
    });
  };
}

export function setBranchId(branchId: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: FILTER_SET_BRANCH_ID,
      branchId
    });
  };
}

export function setContains(contains: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: FILTER_SET_CONTAINS,
      contains
    });
  };
}

export function initCategories() {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    dispatch({
      type: FILTER_INIT_CATEGORIES,
      categories: getInitialCategoriesState(
        R.pathOr([], ["categories", "itemTypeCategories"], getState())
      )
    });
  };
}

export const initialCategorySelection = false;

export const getInitialCategoriesState = R.map((category: any) => ({
  id: category.id,
  isSelected: initialCategorySelection,
  isIndeterminate: false,
  itemTypes: R.map((itemType: any) => ({
    id: itemType.id,
    isSelected: initialCategorySelection
  }))(category.itemTypes)
}));

export function toggleCategory(categoryId: string) {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    dispatch({
      type: FILTER_TOGGLE_CATEGORY,
      categories: toggleSelectionToCategory(getState(), categoryId)
    });
  };
}

// TODO: refactor to ramda???
const toggleSelectionToCategory = (
  state: IAppState,
  categoryId: string
): ICategorySelect[] => {
  return R.compose(
    R.map(
      R.when(R.propEq("id", categoryId), (categorySelect: ICategorySelect) => {
        categorySelect.isSelected = !categorySelect.isSelected;
        categorySelect.isIndeterminate = false;
        categorySelect.itemTypes = R.map((itemType: ICategoryItemSelect) => {
          itemType.isSelected = categorySelect.isSelected;
          return itemType;
        })(categorySelect.itemTypes || []);
        return categorySelect;
      })
    ),
    R.pathOr([], ["filter", "categories"])
  )(state);
};

export function toggleCategoryItem(categoryId: string, itemId: string) {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    dispatch({
      type: FILTER_TOGGLE_CATEGORY_ITEM,
      categories: toggleSelectionToCategoryItem(getState(), categoryId, itemId)
    });
  };
}

// TODO: refactor to ramda???
const toggleSelectionToCategoryItem = (
  state: IAppState,
  categoryId: string,
  itemId: string
) => {
  return R.compose(
    R.map(
      R.when(R.propEq("id", categoryId), (categorySelect: ICategorySelect) => {
        let selectedCount = 0;
        const itemCount = categorySelect.itemTypes.length;
        categorySelect.itemTypes = R.map((item: ICategoryItemSelect) => {
          if (item.id === itemId) item.isSelected = !item.isSelected;
          if (item.isSelected) {
            selectedCount++;
          }
          return item;
        })(categorySelect.itemTypes || []);
        categorySelect.isSelected = selectedCount !== 0;
        categorySelect.isIndeterminate =
          selectedCount !== 0 && selectedCount < itemCount;

        return categorySelect;
      })
    ),
    R.pathOr([], ["filter", "categories"])
  )(state);
};

interface IOptions {
  debounce: boolean;
}

const dispatchDebouncingFetchFilteredItems = () => ({
  type: DEBOUNCING_FETCH_FILTERED_ITEMS
});

export function getFilteredItemsAction(options: IOptions = { debounce: true }) {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const filter: IFilter = filterStateToFilterItemsInput(getState().filter);
    if (options.debounce) {
      dispatch(dispatchDebouncingFetchFilteredItems());
      debounceFetch(dispatch, filter);
    } else {
      dispatch<any>(getItemsAction(filter));
    }
  };
}

const debounceFetch = debounce((dispatch: Dispatch, filter: IFilter) => {
  dispatch<any>(getItemsAction(filter));
}, 1000);
