import React, { Component } from "react";
import * as R from "ramda";

import styles from "./RadioFilter.module.scss";

interface IRadioButtonValues {
  value: string;
  label: string;
}

interface IProps {
  filterId: string;
  values: IRadioButtonValues[];
  defaultValue?: string;
  handleChange: (value: string | undefined) => void;
}

interface IState {
  selected?: string | undefined;
}

class RadioFilter extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      selected: props.defaultValue
    };
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevState.selected !== this.state.selected) {
      this.props.handleChange(this.state.selected);
    }
    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.setState({
        selected: this.props.defaultValue
      });
    }
  }

  handleRadioButtonClick = (value: string) => () => {
    this.setState(prevState => ({
      selected: prevState.selected === value ? undefined : value
    }));
  };

  renderRadioButtons() {
    return R.map(
      (item: IRadioButtonValues) => (
        <div
          id={item.value}
          key={item.value}
          className={`${styles.radioButton} ${
            this.state.selected === item.value ? styles.active : ""
          }`}
          onClick={this.handleRadioButtonClick(item.value)}
        >
          {item.label}
        </div>
      ),
      this.props.values
    );
  }

  render() {
    return (
      <div id={this.props.filterId} className={styles.wrapper}>
        {this.renderRadioButtons()}
      </div>
    );
  }
}

export default RadioFilter;
