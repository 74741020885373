import React, { Component } from "react";
import * as R from "ramda";
import { Collapse } from "@material-ui/core";

import CategoryFilterItemLine from "../CategoryFilterItemLine/CategoryFilterItemLine";
import { ICategorySelect } from "../../reducers/Filter";

import styles from "./CategoryFilterItem.module.scss";

interface IProps {
  category: any;
  categorySelect: ICategorySelect;
  toggleCategory: any;
  toggleCategoryItem: any;
}

interface IState {
  expanded: boolean;
}

class CategoryFilterItem extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      expanded: false
    };

    this.handleCollapseClick = this.handleCollapseClick.bind(this);
    this.anyItemSelected = this.anyItemSelected.bind(this);
    this.allItemsSelected = this.allItemsSelected.bind(this);
    this.itemSelected = this.itemSelected.bind(this);
  }

  handleCollapseClick() {
    this.setState(prevState => ({
      expanded: !prevState.expanded
    }));
  }

  anyItemSelected() {
    return !!R.find(R.propEq("isSelected", true))(
      this.props.categorySelect.itemTypes
    );
  }

  allItemsSelected() {
    return !R.find(R.propEq("isSelected", false))(
      this.props.categorySelect.itemTypes
    );
  }

  itemSelected(id: string) {
    return !!R.compose(
      R.propOr(false, "isSelected"),
      R.find(R.propEq("id", id)),
      R.pathOr([], ["itemTypes"])
    )(this.props.categorySelect);
  }

  handleCategorySelect = () => {
    this.props.toggleCategory(this.props.category.id);
  };

  renderHeader() {
    return (
      <CategoryFilterItemLine
        label={this.props.category.name}
        isChecked={this.props.categorySelect.isSelected || false}
        handleSelect={this.handleCategorySelect}
        header
        isIndeterminate={this.props.categorySelect.isIndeterminate || false}
        onCollapseClick={this.handleCollapseClick}
        pointerCursor={!R.isEmpty(this.props.category.itemTypes)}
      />
    );
  }

  handleCategoryItemSelect = (itemId: any) => () => {
    this.props.toggleCategoryItem(this.props.category.id, itemId);
  };

  renderItems() {
    return R.map((item: any) => (
      <CategoryFilterItemLine
        label={item.name}
        key={item.id}
        isChecked={this.itemSelected(item.id)}
        handleSelect={this.handleCategoryItemSelect(item.id)}
      />
    ))(this.props.category.itemTypes);
  }

  render() {
    return (
      <div className={styles.category}>
        {this.renderHeader()}
        <Collapse in={this.state.expanded} className={styles.collapse}>
          {this.renderItems()}
        </Collapse>
      </div>
    );
  }
}

export default CategoryFilterItem;
