import React, { ReactNode } from "react";
import { MuiThemeProvider } from "@material-ui/core";

import AppBar from "../../containers/AppBar";
import SidePanel from "../../components/SidePanel";

import theme from "./Theme";
import styles from "./Page.module.scss";

interface IProps {
  children: ReactNode;
  hideAppBar?: boolean;
  sidePanelContent?: ReactNode;
  appBarContent?: ReactNode;
  actionButtons?: ReactNode;
}

const renderSidePanel = (sidePanelContent: ReactNode) => {
  if (sidePanelContent) {
    return <SidePanel>{sidePanelContent}</SidePanel>;
  }
  return undefined;
};

// TODO: move ThemeProvider to App.tsx
const Page = ({
  children,
  hideAppBar,
  sidePanelContent,
  appBarContent,
  actionButtons
}: IProps) => (
  <MuiThemeProvider theme={theme}>
    <div className={styles.page}>
      {!hideAppBar && (
        <AppBar content={appBarContent} actionButtons={actionButtons} />
      )}
      <div className={styles.content}>
        {renderSidePanel(sidePanelContent)}
        <div className={styles.children}>{children}</div>
      </div>
    </div>
  </MuiThemeProvider>
);

export default Page;
