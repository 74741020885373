import { Dispatch } from "redux";

import {
  GET_ALL_CATEGORIES_REQUEST,
  GET_ALL_CATEGORIES_SUCCESS,
  GET_ALL_CATEGORIES_ERROR
} from "../constants/ActionTypes";
import { ItemTypeCategory } from "../common/api/fragments/__generatedTypes__/ItemTypeCategory";
import getAllCategories from "../common/api/queries/allCategoriesQuery/allCategoriesQuery";

function dispatchGetAllCategoriesRequest(): any {
  return {
    type: GET_ALL_CATEGORIES_REQUEST
  };
}

function dispatchGetAllCategoriesSuccess(
  itemTypeCategories: ItemTypeCategory[]
): any {
  return {
    type: GET_ALL_CATEGORIES_SUCCESS,
    itemTypeCategories
  };
}

function dispatchGetAllCategoriesError(message: string): any {
  return {
    type: GET_ALL_CATEGORIES_ERROR,
    errorMessage: message
  };
}

export function getAllCategoriesAction() {
  return (dispatch: Dispatch) => {
    dispatch(dispatchGetAllCategoriesRequest());
    return getAllCategories()
      .then(res => {
        dispatch(dispatchGetAllCategoriesSuccess(res));
      })
      .catch(res => {
        dispatch(dispatchGetAllCategoriesError("authentication failed"));
        throw new Error(res);
      });
  };
}

export type getAllCategoriesActionType = () => void;
