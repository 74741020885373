import React from "react";

import PhotoGallery from "../PhotoGallery";
import ItemDetails from "../ItemDetails";
import IItem from "../../common/interfaces/IItem";
import { Item_photos } from "../../common/api/fragments/__generatedTypes__/Item";

import styles from "./DetailPageLayout.module.scss";

interface IProsp {
  photos: Item_photos[];
  item: IItem;
}

const DetailPageLayout = ({ photos, item }: IProsp) => (
  <div className={styles.detailPageLayout}>
    <div className={styles.content}>
      <div className={styles.photos}>
        <PhotoGallery photos={photos} />
      </div>
      <div className={styles.itemDetail}>
        <ItemDetails item={item} />
      </div>
    </div>
  </div>
);

export default DetailPageLayout;
