import gql from "graphql-tag";
import { FetchResult } from "react-apollo";
import client from "../../client";

const CREATE_INVENTORY_FILE_MUTATION = gql`
    mutation {
        createCurrentWarehouseInventoryFile {
            fileId
        }
    }
`;


export default function generateInventoryFile(): Promise<FetchResult<any>> {
  return client.mutate<any, any>({
    mutation: CREATE_INVENTORY_FILE_MUTATION
  });
}
