import * as R from "ramda";

import { initialCategorySelection } from "../../actions/Filter";
import { IFilterState } from "../../reducers/Filter";

export default (filter: IFilterState) => {
  let isDefault = true;

  if (R.equals(filter, {})) return isDefault;

  const categories = R.prop("categories", filter);
  if (categories) {
    R.forEach((category: any) => {
      if (!isDefault) return;
      if (category.isSelected !== initialCategorySelection) {
        isDefault = false;
        return;
      }
    }, categories);
  }

  if (R.prop("stateType", filter)) {
    isDefault = false;
    return isDefault;
  }

  if (R.prop("branchId", filter)) {
    isDefault = false;
    return isDefault;
  }

  return isDefault;
};
