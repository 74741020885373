import ApolloClient from "apollo-boost";

let _token: string = "";

const client = new ApolloClient({
  uri: process.env.REACT_APP_ENDPOINT+'/graphql',
  headers: {
    "x-api-key":
      "dWyqRYj9BfNbh28qvg49ebVBTocTt6ZGU5UZ5navhLoHWkSpx8FALUT3MNzAev27"
  },
  request: async operation => {
    operation.setContext({
      headers: {
        Authorization: _token ? `Bearer ${_token}` : ""
      }
    });
  }
});

export function setToken(newToken: string): void {
  _token = newToken;
}

export function clearToken(): void {
  _token = "";
}

export default client;
