import gql from "graphql-tag";
import { FetchResult } from "react-apollo";

import client from "../../client";
import { UserFragment } from "../../fragments/UserFragment";

import {
  loginMutation,
  loginMutationVariables
} from "./__generatedTypes__/loginMutation";

const LOGIN_MUTATION = gql`
  mutation loginMutation($input: LoginInput!) {
    login(input: $input) {
      token
      user {
        ...User
      }
    }
  }
  ${UserFragment}
`;

export default function login(
  input: loginMutationVariables
): Promise<FetchResult<loginMutation>> {
  return client.mutate<loginMutation, loginMutationVariables>({
    mutation: LOGIN_MUTATION,
    variables: input
  });
}
