import { Action } from "redux";

import { IAuthState, defaultAuthState, authReducer } from "./Auth";
import { defaultItemsState, IItemsState, itemsReducer } from "./Items";
import {
  defaultCategoriesState,
  ICategoriesState,
  categoriesReducer
} from "./Categories";
import { IFilterState, filterReducer, defaultFilterState } from "./Filter";

export interface IAppState {
  auth: IAuthState;
  items: IItemsState;
  categories: ICategoriesState;
  filter: IFilterState;
}

export const defaultState = () => ({
  auth: defaultAuthState,
  items: defaultItemsState,
  categories: defaultCategoriesState,
  filter: defaultFilterState
});

export function mainReducer(state: IAppState = defaultState(), action: Action) {
  return {
    auth: authReducer(state.auth, action),
    items: itemsReducer(state.items, action),
    categories: categoriesReducer(state.categories, action),
    filter: filterReducer(state.filter, action)
  };
}
