import gql from "graphql-tag";

export const DimensionsFragment = gql`
  fragment Dimensions on DimensionsObjectType {
    id
    depth
    length
    height
  }
`;
