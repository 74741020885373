import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  shape: {
    borderRadius: 0
  },
  shadows: [
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none"
  ],
  overrides: {
    // Name of the component ⚛️ / style sheet
    MuiButton: {
      // Name of the rule
      root: {
        // Some CSS
        color: "white",
        height: 44,
        backgroundColor: "rgb(47,148,240)"
      }
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 18,
        marginRight: 0,
        marginBottom: 0,
        marginLeft: 0,
        "&:last-child": {
          marginBottom: 28
        }
      }
    },
    MuiOutlinedInput: {
      input: {
        paddingTop: 14.5,
        paddingRight: 19,
        paddingBottom: 14.5,
        paddingLeft: 19
      }
    },
    MuiCardContent: {
      root: {
        padding: 30,
        "&:last-child": {
          paddingBottom: 30
        }
      }
    },
    MuiTableCell: {
      root: {
        padding: "0 10px 0 0"
      },
      head: {
          fontWeight: "bold"
      }
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 17,
    htmlFontSize: 17,
    fontFamily: '"Helvetica", "Arial", sans-serif'
  }
});

export default theme;
