import gql from 'graphql-tag';

import { DimensionsFragment } from './DimensionsFragment';

export const ItemTypeWithoutCategoryFragment = gql`
  fragment ItemTypeWithoutCategory on ItemTypeObjectType {
    id
    name
    dimensions {
      ...Dimensions
    }
  }
  ${DimensionsFragment}
`;
