import {
  USER_LOGOUT,
  GET_ITEMS_REQUEST,
  GET_ITEMS_SUCCESS,
  GET_ITEMS_ERROR,
  DEBOUNCING_FETCH_FILTERED_ITEMS
} from "../constants/ActionTypes";
import { FetchingStateEnumType } from "../constants/FetchingState";
import IItem from "../common/interfaces/IItem";

export interface IItemsState {
  fetchingState: string;
  items: IItem[];
}

export const defaultItemsState: IItemsState = {
  fetchingState: FetchingStateEnumType.INIT,
  items: []
};

export function itemsReducer(state: IItemsState, action: any): IItemsState {
  switch (action.type) {
    case DEBOUNCING_FETCH_FILTERED_ITEMS: {
      return {
        ...state,
        fetchingState: FetchingStateEnumType.DEBOUNCING_FETCH
      };
    }
    case GET_ITEMS_REQUEST: {
      return {
        fetchingState: FetchingStateEnumType.FETCHING,
        items: state.items
      };
    }
    case GET_ITEMS_SUCCESS: {
      return {
        fetchingState: FetchingStateEnumType.FETCHED,
        items: action.items
      };
    }
    case GET_ITEMS_ERROR: {
      return {
        fetchingState: FetchingStateEnumType.ERROR,
        items: []
      };
    }
    case USER_LOGOUT: {
      return defaultItemsState;
    }
    default:
      return state;
  }
}
