export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_ERROR = "USER_LOGIN_ERROR";
export const USER_LOGOUT = "USER_LOGOUT";

export const GET_ITEMS_REQUEST = "GET_ITEMS_REQUEST";
export const GET_ITEMS_SUCCESS = "GET_ITEMS_SUCCESS";
export const GET_ITEMS_ERROR = "GET_ITEMS_ERROR";

export const GET_ALL_CATEGORIES_REQUEST = "GET_ALL_CATEGORIES_REQUEST";
export const GET_ALL_CATEGORIES_SUCCESS = "GET_ALL_CATEGORIES_SUCCESS";
export const GET_ALL_CATEGORIES_ERROR = "GET_ALL_CATEGORIES_ERROR";

export const DEBOUNCING_FETCH_FILTERED_ITEMS =
  "DEBOUNCING_FETCH_FILTERED_ITEMS";
export const FILTER_RESET = "FILTER_RESET";
export const FILTER_SET_STATE_TYPE = "FILTER_SET_STATE_TYPE";
export const FILTER_SET_BRANCH_ID = "FILTER_SET_BRANCH_ID";
export const FILTER_SET_CONTAINS = "FILTER_SET_CONTAINS";
export const FILTER_INIT_CATEGORIES = "FILTER_INIT_CATEGORIES";
export const FILTER_TOGGLE_CATEGORY = "FILTER_TOGGLE_CATEGORY";
export const FILTER_TOGGLE_CATEGORY_ITEM = "FILTER_TOGGLE_CATEGORY_ITEM";
